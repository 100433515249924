(function () {
    angular.module('informaApp')
        .service('Tooltip', ['d3', TooltipService]);

    function TooltipService(d3) {
        var defaultConfig = {
            x: 0,
            y: 0,

            getTemplate: null
        };

        return function (container, config, className = '') {
            config = _.merge({}, defaultConfig, config);

            var offsetYX = [config.y, config.x];

            var tip = d3.tip()
                .attr("class", `d3-tip ${className}`)
                .offset(offsetYX)
                .html(config.getTemplate ? config.getTemplate : getTemplate(config.tooltipRows));

            container.call(tip);

            this.show = function (tooltipRows) {
                tip.show(tooltipRows);
            };

            this.hide = function () {
                tip.hide();
            }
        };

        function getTemplate() {
            return function (tooltipRows) {
                if (tooltipRows) {
                    var rows = tooltipRows.map(function (x) {
                        return '<p class="caption' + (x.bold ? ' selected' : '') + '">' + x.text + '</p>';
                    });
                }

                return '<div>' + rows.join('') + '</div>'
            }
        }
    }
})();